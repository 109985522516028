import { AuthenticationEndpoints } from './axiosAPIGroups'
import { setToSessionStorage } from '../../helpers/storageUtils'
import paths from '../../constants/paths'

export const redirectTo = (path: string) => {
  window.location.replace(`${window.location.origin}${path}`)
}

let fetchingAccessToken = false
let requestQueue = []

const addQueuedRequest = (request) => {
  requestQueue.push(request)
}

const onUnableToRetry = () => {
  setToSessionStorage('redirect_uri', window.location.origin)
  redirectTo(paths.auth.callback)
}

const onAccessTokenFetched = () => {
  requestQueue.forEach((request) => {
    return request()
  })
  requestQueue = []
}

export const setInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config
      if (!('response' in error)) return Promise.reject(error)
      if (error.config.disableTokenRefresh) {
        return Promise.reject(error)
      }
      if (error.response.status === 403 && error.response.data.code === 126) {
        redirectTo(paths.auth.forbidden)
      }
      if (error.response.status === 401) {
        if (error.response.data.code === 123) {
          if (!fetchingAccessToken) {
            fetchingAccessToken = true
            AuthenticationEndpoints.tokenFromRefreshTokenAuthRefreshTokenPost({
              disableTokenRefresh: true,
            })
              .then(() => {
                fetchingAccessToken = false
                onAccessTokenFetched()
              })
              .catch(() => onUnableToRetry())
          }
          return new Promise((resolve) => {
            const config = { ...originalRequest, disableTokenRefresh: true }
            addQueuedRequest(() => resolve(axiosInstance(config)))
          })
        } else if (error.response.data.code !== 110) {
          onUnableToRetry()
        }
        // TODO handle 401 code 110 - permission, unauthorized
      }
      return Promise.reject(error)
    }
  )
}
